<template>
    <div>
        <!-- 面板页 -->
        <div v-if="currentPage == 'tabs'">
            <el-tabs v-model="currentMenuValue">
                <el-tab-pane v-for="item in menuList" :key="item.value" :label="item.label"
                    :name="item.name"></el-tab-pane>
            </el-tabs>
            <!-- 全部 -->
            <record-list v-if="currentMenuValue == 'all'" @toPage="toPage"></record-list>
            <!-- 文件上传 -->
            <file-upload v-if="currentMenuValue == 'file'" @toPage="toPage" @toMenu="toMenu"></file-upload>
            <!-- 已完成分析 -->
            <analysis-finish-list v-if="currentMenuValue == 'finish'" @toMenu="toMenu"
                @toPage="toPage"></analysis-finish-list>
            <!-- 对比分析列表 -->
            <analysis-contrast-list v-if="currentMenuValue == 'compare'"></analysis-contrast-list>
        </div>
        <!-- 视频分析页 -->
        <analysis v-if="currentPage == 'analysis'" @toPage="toPage"></analysis>
        <!-- 上传文件分析页 -->
        <upload-file-analysis v-if="currentPage == 'fileUploadAnalysis'" @toPage="toPage"></upload-file-analysis>
    </div>
</template>

<script>
import recordList from "./record-list.vue";
import analysisFinishList from "./analysis/analysis-finish-list.vue";
import analysis from "./analysis/analysis.vue";
import analysisContrastList from "./contrast/analysis-contrast-list.vue";
import fileUpload from "./fileUpload/index.vue";
import uploadFileAnalysis from "./fileUpload/file-upload-analysis.vue";
export default {
    components: {
        recordList,
        analysisFinishList,
        analysis,
        analysisContrastList,
        fileUpload,
        uploadFileAnalysis
    },
    data() {
        return {
            currentPage: "tabs",
            currentMenuValue: 'all',
            menuList: [
                { label: "全部", name: 'all' },
                { label: "文件上传", name: 'file' },
                { label: "已完成分析", name: 'finish' },
                { label: "对比分析", name: 'compare' },

            ],
        };
    },

    mounted() {
        let replayPageMenu = this.$store.state.replayPageMenu;
        if (replayPageMenu) {
            this.currentMenuValue = replayPageMenu.menu;
            this.currentPage = replayPageMenu.page;
            this.$store.commit("saveReplayPageMenu", null);
        }
    },

    methods: {
        toMenu(menu) {
            this.currentMenuValue = menu;
        },
        toPage(url) {
            this.currentPage = url;
        }
    },
};
</script>

<style scoped lang="less">
/deep/ .el-tabs__header {
    margin: 0px;
}
</style>